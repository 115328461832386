import { Button, NavBar, darkTheme } from 'hudskit-framework-react';
import uptimeLogoDark from 'images/logos/uptime-logo-dark.svg';
import uptimeLogoLight from 'images/logos/uptime-logo-light.svg';
import { useTheme } from 'styled-components';
import { useDropdownMenuClick } from 'core/hooks/use-dropdown-menu-click';
import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';
import useMediaQuery from 'core/hooks/use-media-query';
import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import { device } from 'core/styles/variables';
import { displayFaq } from 'core/config';
import { RoutePaths } from 'core/models';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import NavMenu from '../NavMenu/NavMenu';
import MobileNavMenu from '../MobileNavMenu';
import * as S from './styled';
import MenuWithDropdown from '../MenuWithDropdown';

const Header = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isDesktop = useMediaQuery(device.lg);
  const { t } = useTranslationWrapper(InternationalizationNameSpace.Core);

  const { dropdownMenu, dropdownRef, displayDropdownMenu } = useDropdownMenuClick();

  const logo = {
    src: theme === darkTheme ? uptimeLogoDark : uptimeLogoLight,
    alt: t('CORE:logo.logoDesc'),
    height: 28,
    width: 130,
  };
  const logoMobile = {
    src: theme === darkTheme ? uptimeLogoDark : uptimeLogoLight,
    alt: t('CORE:logo.logoDesc'),
    height: 24,
    width: 113,
  };
  const userSettingsOnClickHanlder = useCallback(() => {
    navigate(RoutePaths.UserInfo);
  }, [navigate]);
  return (
    <NavBar
      rightSideArea={(
        <S.RightSideAreaDiv ref={dropdownRef}>
          {displayFaq && (
            <>
              <S.DropdownContainerDiv id="utcInfoIcon">
                <Button
                  variant="transparent"
                  size="small"
                  onClick={displayDropdownMenu}
                  icon={{ type: dropdownMenu ? 'notification.info' : 'notification.info_outlined', position: 'left' }}
                />
              </S.DropdownContainerDiv>
              {dropdownMenu && (
              <S.DropdownDiv data-testid="dropdown">
                <MenuWithDropdown />
              </S.DropdownDiv>
              )}
            </>
          )}
          <div id="utcAvatar">
            <Button
              variant="transparent"
              size="small"
              onClick={userSettingsOnClickHanlder}
              icon={{ type: 'action.avatar_outlined', position: 'left' }}
            />
          </div>
        </S.RightSideAreaDiv>

      )}
      logoLink={RoutePaths.Dashboard}
      primaryArea={isDesktop && <NavMenu />}
      desktopLogo={logo}
      mobileLogo={logoMobile}
      leftSideArea={(
        !isDesktop && <MobileNavMenu />
      )}
    />
  );
};
export default Header;
